// src/pages/TheMusicBox.js
import React from 'react';

const TheMusicBox = () => {
    return <div>

        <div className="entry-image mb-5">
            <img src="/assets/images/the_music_box_wide_01.jpg" />
        </div>

        <section className="page-title bg-transparent">
			<div className="container">
				<div className="page-title-row">
					<div className="page-title-content">
						<h1>The Music Box Quartet</h1>
					</div>
				</div>
			</div>
		</section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">

                        <div className="col-md-4">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/activities/IMG_6193.jpeg`} />
                            <br /><br />
                            <img src={`${process.env.PUBLIC_URL}/assets/images/activities/IMG_8994.jpeg`} />
						</div>

						<div className="col-md-8">
                            <p>
                                My passion for playing the cello has always been deeply rooted in the way music can evoke emotions and create connections. From the moment I began learning the cello, I was captivated by its rich, resonant tones and the way it can convey everything from the deepest sorrow to the brightest joy. This instrument has been my voice, allowing me to express emotions that words often cannot capture.
                            </p>
                            <p>
                                I joined the Music Box Quartet because I wanted to use my love for the cello to make a difference in the lives of others. The idea of bringing comfort, joy, and a sense of nostalgia to seniors in assisted living and memory care resonated with me on a personal level. Music has the power to transcend time and space, and being able to share that experience with those who may feel isolated or disconnected is incredibly meaningful. Our performances are more than just playing notes; they are about creating moments of connection and happiness through the universal language of music.
                            </p>

                            <p>
                                If you're interested in learning more about our quartet and the work we do, I invite you to visit our website <a href="https://themusicboxquartet.wixsite.com/website">themusicboxquartet.wixsite.com/website</a>. There, you can find out more about our mission, upcoming performances, and how you can support our efforts to bring music to those who need it most. Thank you for being part of this journey with us, and I hope our music brings as much joy to you as it does to me.
                            </p>

                            <a href="https://themusicboxquartet.wixsite.com/website" title="The Music Box Quartet">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/logo_the_music_box.webp`} className="" />
                            </a>
                            <br />
                            <a href="https://themusicboxquartet.wixsite.com/website" class="button button-border py-1 text-transform-none ls-0 fw-normal button-dark border-width-1 border-color h-bg-color">Visit The Music Box Quartet</a>
						</div>
                    </div>
                </div>
            </div>
        </section>

    </div>;
};

export default TheMusicBox;
