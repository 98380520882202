// src/pages/Footer.js
import React from 'react';



const Footer = () => {
    let thisYear = new Date().getFullYear();

    return <footer id="footer" className="border-0 bg-white">
        <div className="container">
            <div className="footer-widgets-wrap py-lg-6">
                <div className="row col-mb-30">
                    <div className="col-lg-2 col-md-3 col-6">
                        <div className="widget widget_links widget-li-noicon">
                            <h4 className="ls-0 text-transform-none">jazlynnchuo</h4>
                            <ul className="list-unstyled iconlist ms-0">
                                <li><a href="/">Home</a></li>
                                <li><a href="/about">About</a></li>
                                <li><a href="/resume">Résumé</a></li>
                                <li><a href="/contact">Contact Me</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-6">
                        <div className="widget widget_links widget-li-noicon">
                            <h4 className="ls-0 text-transform-none">Projects</h4>
                            <ul className="list-unstyled iconlist ms-0">
                                <li><a href="/ai">AI Project</a></li>
                                <li><a href="/planet_protector">Planet Protector</a></li>
                                <li><a href="/the_music_box_quartet">The Music Box</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-6">
                        <div className="widget widget_links widget-li-noicon">
                            <h4 className="ls-0 text-transform-none">More</h4>
                            <ul className="list-unstyled iconlist ms-0">
                                <li><a href="/blog">Blogs</a></li>
                                <li><a href="/gallery">Gallery</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-6">
                        <div className="widget widget_links widget-li-noicon">
                            <h4 className="ls-0 text-transform-none">Social</h4>
                            <ul className="list-unstyled iconlist ms-0">
                                <li><a href="https://linkedin.com" target="_blank"><i className="bi-instagram"></i> LinkedIn</a></li>
                                <li><a href="https://youtube.com/c/SemiColonweb/videos" target="_blank"><i className="fa-brands fa-youtube"></i> YouTube</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="widget subscribe-widget" data-loader="button">
                            {/* <h4>Subscribe Us</h4>
                            <h5 className="font-body op-04"><strong>Subscribe</strong> to Our Newsletter to get Important News, Amazing Offers &amp; Inside Scoops:</h5>
                            <div className="widget-subscribe-form-result"></div>
                            <form id="widget-subscribe-form" action="include/subscribe.php" method="post" className="mb-0">
                                <div className="input-group">
                                    <input type="email" id="widget-subscribe-form-email" name="widget-subscribe-form-email" className="form-control required email" placeholder="Enter your Email Address" />
                                    <button className="btn btn-dark bg-color px-3 input-group-text" type="submit">Subscribe</button>
                                </div>
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="copyrights" className="py-3 bg-color-light">
            <div className="container">
                <div className="d-flex justify-content-between op-04">
                    <a href="/"><span>&copy; {thisYear} All Rights Reserved by jazlynn_chuo.</span></a>
                    <div className="copyright-links"><a href="/terms_of_use">Terms of Use</a> / <a href="/privacy_policy">Privacy Policy</a></div>
                </div>
            </div>
        </div>
    </footer>;
};

export default Footer;