// src/pages/BlogRead.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const BlogRead = () => {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(`https://api.jazlynnchuo.com/api/blog/${id}`);
                setArticle(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchArticle();
    }, [id]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return <div>
        <section className="page-title bg-transparent">
            <div className="container">
                <div className="page-title-row">
                    <div className="page-title-content">
                        <h1>Blog: {article.title}</h1>
                        <h5>By {article.author} on {new Date(article.created_at).toLocaleDateString()}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">
						<div className="col-md-12">
                            <img src={`${article.photo_01}`} alt={article.title} title={article.title} />
                            <br /><br />
						</div>

						<div className="col-md-12">
                            {article.content}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>;
};

export default BlogRead;
