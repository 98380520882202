// src/AppRoutes.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Resume from './pages/Resume';
import Blog from './pages/Blog';
import BlogRead from './pages/BlogRead';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Project from './pages/Project';
import AI from './pages/Ai';
import PlanetProtector from './pages/PlanetProtector';
import TheMusicBox from './pages/TheMusicBox';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound from './pages/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';

const AppRoutes = () => {
  return (
    <Router>
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogRead />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/projects" element={<Project />} />
            <Route path="/ai" element={<AI />} />
            <Route path="/planet_protector" element={<PlanetProtector />} />
            <Route path="/the_music_box_quartet" element={<TheMusicBox />} />
            <Route path="/terms_of_use" element={<TermsOfUse />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
    </Router>
  );
};

export default AppRoutes;
