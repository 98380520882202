// src/pages/About.js
import React from 'react';

const About = () => {
    return <div>
        <section className="page-title bg-transparent">
            <div className="container">
                <div className="page-title-row">
                    <div className="page-title-content">
                        <h1>About</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">
						<div className="col-md-4">
                            <img src={`/assets/images/portrait_jazlynn_chuo.jpg`} />
                            <br /><br />
						</div>

						<div className="col-md-8">
                            <p>
                                Welcome to my website! I'm a high school senior with a deep passion for exploring the intersections between the environment, pathology, and technology, all while nurturing my love for music. Whether I'm analyzing the impacts of climate change on public health through AI and machine learning or performing as a cellist in an orchestra, I'm driven by a desire to make meaningful connections between my diverse interests. I believe that the blend of science, technology, and the arts can lead to innovative solutions and powerful expressions that resonate on both personal and global levels.
							</p>
                            <p>
                                Music has always been a significant part of my life. As a dedicated cellist, I've had the privilege of performing in orchestras and sharing my love for music through teaching. There's something truly special about guiding others in their musical journey and witnessing the joy it brings. My experiences in music have taught me the importance of creativity, discipline, and collaboration, which I also bring to my academic pursuits in environmental science and pathology. These fields allow me to combine my curiosity for understanding the world with my commitment to making a positive impact.
	                        </p>
                            <p>
                                Thank you for visiting my website! I invite you to explore more about my projects, performances, and interests. Whether you're here to learn more about my work in AI and environmental health, my musical journey, or simply to connect, I'm glad you're here. I hope my passion and dedication inspire you, and I look forward to sharing more with you.
                            </p>
							
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>;
};

export default About;
