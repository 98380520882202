// src/pages/PlanetProtector.js
import React from 'react';

const PlanetProtector = () => {
    return <div>

		<div className="entry-image mb-5">
            <img src={`/assets/images/activities/banner_planet_protectors.jpg`}  />
        </div>

        <section className="page-title bg-transparent">
			<div className="container">
				<div className="page-title-row">
					<div className="page-title-content">
						<h1>Planet Protectors</h1>
					</div>
				</div>
			</div>
		</section>

		<section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">
						<div className="col-md-4">
                            <img src={`/assets/images/activities/planet_protectors_01.jpg`} />
                            <br /><br />
						</div>

						<div className="col-md-8">
                            <p>
								Welcome to Planet Protectors, a project I created as a high school student with a deep passion for environmental conservation and a commitment to making a difference. The idea for Planet Protectors was born out of my desire to empower the next generation with the knowledge and tools they need to combat climate change. I believe that even young children can play a vital role in protecting our planet, and through this project, I hope to inspire them to take action. My goal is to spread awareness about the critical issues of overconsumption, pollution, and environmental preservation in a way that is both positive and empowering.
							</p>
                            <p>
								The motivation behind Planet Protectors stems from my own experiences as a student who has seen the effects of climate change and environmental degradation firsthand. I realized that by reaching out to younger students, I could help plant the seeds of environmental awareness early on. I’m passionate about science and environmental education, and I wanted to create a program that makes these important topics accessible and engaging for children. I believe that by teaching them about the importance of preserving our environment, we can foster a generation that is more aware, responsible, and committed to making a positive impact on the world.
	                        </p>
                            <p>
								This project is important to me because I understand that climate change is a gradual but significant threat that requires immediate action. The next generation needs to be equipped with the knowledge to understand and address this issue before it’s too late. By teaching young students about the dangers of water pollution, rising sea levels, and the benefits of renewable energy, I hope to instill in them a sense of responsibility towards our planet. My hope is that these lessons will not only inform but also inspire these young minds to take ownership of the environment they will inherit.
                            </p>
							<p>
								Over the course of five days, I guide students through interactive lessons on topics like water pollution, the impact of rising ocean levels, and the importance of wind and solar power. We will also explore the significance of recycling and how small actions can make a big difference. Each lesson is designed to be engaging and relatable, helping students connect with these important issues on a personal level. As a high school student, I’m excited to share my passion for the environment with younger students and help them become true Planet Protectors.
							</p>
							<a href={`/assets/media/pdf/Planet_Protectors.pdf`} >
								<img src={`/assets/images/activities/pdf_planet_protectors.jpg`} />
							</a>
                            <br />
                            <a href={`/assets/media/pdf/Planet_Protectors.pdf`} class="button button-border py-1 text-transform-none ls-0 fw-normal button-dark border-width-1 border-color h-bg-color">Download Planet Protectors PDF</a>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>;
};

export default PlanetProtector;
