// src/pages/NotFound.js
import React from 'react';

const NotFound = () => {

    return <div>
        <section className="page-title bg-transparent">
            <div className="container">
                <div className="page-title-row">
                    <div className="page-title-content">
                        <h1>404</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">
                        <div className="col-12">
                            <p>Oops! The page you're looking for doesn't exist.</p>
                            <a href="/" style={{ color: '#007bff' }}>Go back to Home</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

};

export default NotFound;