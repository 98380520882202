import React, { useState, useEffect, useRef } from 'react';
// import './Home.css';

const Home = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const carouselRef = useRef(null); // Ref to the carousel container

    const items = [
        {
            imgSrc: `/assets/images/home_banner_02.jpg`,
            title: 'Image 1',
        },
        {
            imgSrc: `/assets/images/home_banner_01.jpg`,
            title: 'Image 2',
        },
        {
            imgSrc: `/assets/images/home_banner_03.jpg`,
            title: 'Image 3',
        },
        {
            imgSrc: `/assets/images/home_banner_04.jpg`,
            title: 'Image 4',
        },
    ];

    useEffect(() => {
        // Preload all images
        const preloadImages = () => {
            const promises = items.map((item) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = item.imgSrc;
                    img.onload = resolve;
                    img.onerror = reject;
                });
            });

            Promise.all(promises)
                .then(() => setIsLoaded(true))
                .catch((error) => {
                    console.error("Image preloading failed", error);
                });
        };

        preloadImages();
    }, [items]);

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
        if (isLoaded) {
            const interval = setInterval(() => {
                handleNext();
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [isLoaded]);

    useEffect(() => {
        if (isLoaded && carouselRef.current) {
            const activeSlide = carouselRef.current.querySelector('.carousel-slide.active img');
            if (activeSlide) {
                carouselRef.current.style.height = `${activeSlide.offsetHeight}px`;
            }
        }
    }, [activeIndex, isLoaded]);

    return (
        <div>
            <div className="carousel-container">
                {isLoaded ? (
                    items.map((item, index) => (
                        <div
                            key={index}
                            className={`carousel-slide ${index === activeIndex ? 'active' : ''}`}
                        >
                            <img
                                src={item.imgSrc}
                                alt={item.title}
                                className="carousel-image"
                            />
                        </div>
                    ))
                ) : (
                    <div className="carousel-loading">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-6" style={{ height: "12.5rem" }}>
                                <div className="css3-spinner">
                                    <div className="css3-spinner-bounce1"></div>
                                    <div className="css3-spinner-bounce2"></div>
                                    <div className="css3-spinner-bounce3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <button className="carousel-button left" onClick={handlePrev}>
                    &lt;
                </button>
                <button className="carousel-button right" onClick={handleNext}>
                    &gt;
                </button>

            </div>

            <div className="container-fluid">

                <div className="row align-items-lg-center col-mb-30">

                    <div className="col-xl-8 col-lg-6 px-lg-0 min-vh-50 min-vh-lg-75" style={{background: "url('assets/images/activities/banner_collage_01.jpg') no-repeat center center", backgroundSize: "cover"}}>
                    </div>

                    <div className="col-xl-4 col-lg-6 px-lg-5 py-5">
                        <h3 className="h1 mb-4 fw-normal">
                            Love of Music, Dedication to Environment Education
                        </h3>
                        <p>
                            Hi there, I'm excited to share a little bit about myself, my passions, and the projects I've been working on. In the About Me section, you can get to know more about my journey, interests, and aspirations. Feel free to check out my Resume to learn about my experiences and skills. Dive into my Projects, where you'll find the AI project I'm currently developing, my work with the Music Box Quartet group, and the Planet Protectors initiative, where I focus on environmental education. Don't miss out on my Blog for updates and insights into the topics I care about, and explore the Gallery for a visual peek into my world. I invite you to explore and get inspired!
                        </p>
                        <a href="/about" className="button button-border m-0 button-dark border-width-1 border-default h-bg-color">
                            Discover Now
                        </a>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Home;
