import React, { useState, useEffect } from 'react';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Close the menu when tapping outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!document.querySelector('.primary-menu-jc').contains(event.target) && !document.querySelector('.burger-menu-icon').contains(event.target)) {
                setMenuOpen(false);
            }
        };
        if (menuOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [menuOpen]);

    return (
        <header id="header" className="header-size-sm border-bottom-0">
            <div id="header-wrap">
                <div className="container">
                    <div className="header-row justify-content-lg-between">

                        <div id="logo" className="mx-auto col-auto flex-column order-2">
                            <a href="/">
                                <img className="logo-default" srcSet="/assets/images/logo.png 1x, /assets/images/logo@2x.png 2x" src="/assets/images/logo@2x.png" alt="jazlyn_chuo Logo" />
                            </a>
                        </div>

                        <div className="header-misc col-auto col-lg-3 order-3 justify-content-lg-end ms-0 ms-sm-3 px-0"></div>

                        {/* Burger Menu Icon */}
                        <div className="burger-menu-icon" onClick={toggleMenu}>
                            {/* Custom SVG for burger menu */}
                            <svg width="30" height="30" viewBox="0 0 100 80" fill="#000">
                                <rect width="100" height="20"></rect>
                                <rect y="30" width="100" height="20"></rect>
                                <rect y="60" width="100" height="20"></rect>
                            </svg>
                        </div>

                        {/* Navigation Menu */}
                        <nav className={`primary-menu-jc ${menuOpen ? 'menu-open' : ''}`}>
                            <ul className="menu-container-jc">
                                <li className="menu-item-jc">
                                    <a className="menu-link-jc" href="/">Home</a>
                                    <ul className="sub-menu-container-jc">
                                        <li className="sub-menu-item-jc"><a className="menu-link-jc" href="/about">About</a></li>
                                        <li className="sub-menu-item-jc"><a className="menu-link-jc" href="/resume">Résumé</a></li>
                                        <li className="sub-menu-item-jc"><a className="menu-link-jc" href="/contact">Contact Me</a></li>
                                    </ul>
                                </li>
                                <li className="menu-item-jc">
                                    <a className="menu-link-jc" href="/projects">Projects</a>
                                    <ul className="sub-menu-container-jc">
                                        <li className="sub-menu-item-jc"><a className="menu-link-jc" href="/ai">AI Project</a></li>
                                        <li className="sub-menu-item-jc"><a className="menu-link-jc" href="/planet_protector">Planet Protector</a></li>
                                        <li className="sub-menu-item-jc"><a className="menu-link-jc" href="/the_music_box_quartet">The Music Box Quartet</a></li>
                                    </ul>
                                </li>
                                <li className="menu-item-jc"><a className="menu-link-jc" href="/blog">Blog</a></li>
                                <li className="menu-item-jc"><a className="menu-link-jc" href="/gallery">Gallery</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
