// // src/pages/Gallery.js
import React, { useState, useRef, useEffect } from 'react';
// import './Gallery.css';

const Gallery = () => {
    const [selectedSeries, setSelectedSeries] = useState(null);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const lightboxRef = useRef(null);

    const photoSeries = [
        {
            id: 1,
            title: 'Youth Climate Ambassadors',
            subheader: 'April 27, 2024',
            photos: [
                'IMG_0140.jpeg',
                'IMG_0159.jpeg',
                'IMG_0132.jpeg',
                'IMG_0133.jpeg',
            ],
        },
        {
            id: 2,
            title: 'Performance at Sunrise Senior Center',
            subheader: 'May 4, 2024',
            photos: [
                'IMG_0630.jpeg',
                'IMG_0643.jpeg',
            ],
        },
        {
            id: 3,
            title: 'Climate Change Education Lessons',
            subheader: 'Category, Type',
            photos: [
                'IMG_1236.jpeg',
                'IMG_1235.jpeg',
                'IMG_1225.jpeg',
                'IMG_1218.jpeg',
                'IMG_1210.jpeg',
                'IMG_1206.jpeg',
            ],
        },
        {
            id: 4,
            title: 'Performance at Sunrise Senior Center',
            subheader: 'Icons, Illustrations',
            photos: [
                'IMG_6159.jpeg',
                'IMG_6156.jpeg',
                'IMG_6130.jpeg',
                'IMG_6129.jpeg',
                'IMG_6126.jpeg',
                'IMG_6125.jpeg',
                'IMG_6162.jpeg',
                'IMG_6184.jpeg',
                'IMG_6188.jpeg',
                'IMG_6193.jpeg',
                'IMG_6125.jpeg',
            ],
        },
        {
            id: 5,
            title: 'Another Series',
            subheader: 'Category, Type',
            photos: [
                'IMG_7228.jpeg',
                'IMG_7230.jpeg',
                'IMG_7231.jpeg',
                'IMG_7232.jpeg',
                'IMG_7234.jpeg',
                'IMG_7241.jpeg',
                'IMG_7242.jpeg',
                'IMG_7245.jpeg',
                'IMG_7255.jpeg',
                'IMG_7256.jpeg',
            ],
        },
        {
            id: 6,
            title: 'Another Series',
            subheader: 'Category, Type',
            photos: [
                'IMG_9007.jpeg',
                'IMG_8953.jpeg',
                'IMG_8970.jpeg',
                'IMG_8971.jpeg',
                'IMG_8977.jpeg',
                'IMG_8980.jpeg',
                'IMG_8994.jpeg',
                'IMG_9003.jpeg',
                'IMG_9005.jpeg',
            ],
        },
    ];

    const handleOpenSeries = (series) => {
        setSelectedSeries(series);
        setCurrentPhotoIndex(0);
    };

    const handleCloseLightbox = () => {
        setSelectedSeries(null);
    };

    const handlePrevPhoto = () => {
        setCurrentPhotoIndex((prevIndex) =>
            prevIndex === 0 ? selectedSeries.photos.length - 1 : prevIndex - 1
        );
    };

    const handleNextPhoto = () => {
        setCurrentPhotoIndex((prevIndex) =>
            prevIndex === selectedSeries.photos.length - 1 ? 0 : prevIndex + 1
        );
    };

    // Detect Esc key and outside click to close the lightbox
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleCloseLightbox();
            }
        };

        // const handleClickOutside = (event) => {
        //     if (selectedSeries && !event.target.closest('.carousel-image')) {
        //         handleCloseLightbox();
        //     }
        // };

        if (selectedSeries) {
            document.addEventListener('keydown', handleKeyDown);
            // document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
            // document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            // document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectedSeries]);

    return <div>
        <section className="page-title bg-transparent">
            <div className="container">
                <div className="page-title-row">
                    <div className="page-title-content">
                        <h1>Gallery</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">

                            <div className="gallery-container">
                                <div className="masonry">
                                    {photoSeries.map(series => (
                                        <div key={series.id} className="masonry-item">
                                            <div
                                                className="thumbnail"
                                                style={{ backgroundImage: `url(/assets/images/activities/${series.photos[0]})` }}
                                                onClick={() => handleOpenSeries(series)}
                                            >

                                                <div className="overlay">
                                                    <span className="gallery-thumbnail-header">{series.title}</span>
                                                    <span className="gallery-thumbnail-subheader">{series.subheader}</span>
                                                    <div className="icons">
                                                        <button onClick={() => handleOpenSeries(series)}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 64 64"
                                                                width="60px"
                                                                height="60px"
                                                                className="icon-more-photos"
                                                            >
                                                                <circle cx="32" cy="32" r="30" fill="white" stroke="none" />
                                                                <rect x="18" y="22" width="28" height="20" rx="2" ry="2" fill="#d3d3d3"
                                                            transform="rotate(-5 32 32) translate(0, 2)" />
                                                                <rect x="16" y="20" width="28" height="20" rx="2" ry="2" fill="#e0e0e0" />
                                                                <polygon points="18,38 30,24 42,38" fill="#8b8b8b" />
                                                                <circle cx="38" cy="24" r="3" fill="#8b8b8b" />
                                                            </svg>
                                                        </button>
                                                        <button>
                                                            <svg 
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 64 64"
                                                                width="60px"
                                                                height="60px"
                                                                className="icon-more-info"
                                                            >
                                                                <g id="Layer_1-2" data-name="Layer_1">
                                                                    <circle cx="30" cy="30" r="30"  fill="#ffffff"/>
                                                                    <path d="M33.89,23.47l-4.49,15.56c-.25.89-.37,1.47-.37,1.75,0,.16.07.31.2.45s.27.21.42.21c.25,0,.5-.11.74-.33.66-.54,1.45-1.52,2.36-2.93l.74.44c-2.2,3.84-4.55,5.76-7.03,5.76-.95,0-1.7-.27-2.27-.8-.56-.53-.84-1.21-.84-2.02,0-.54.12-1.23.37-2.06l3.04-10.46c.29-1.01.44-1.77.44-2.28,0-.32-.14-.61-.42-.85-.28-.25-.66-.37-1.14-.37-.22,0-.48,0-.79.02l.28-.88,7.42-1.2h1.31ZM32.53,13.4c.9,0,1.67.31,2.29.94.62.63.93,1.39.93,2.28s-.31,1.65-.94,2.28-1.39.94-2.28.94-1.63-.31-2.25-.94c-.63-.63-.94-1.39-.94-2.28s.31-1.65.93-2.28c.62-.63,1.38-.94,2.27-.94Z" fill="#8b8b8b" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {selectedSeries && (
                                    <div className="lightbox">
                                        <div className="lightbox-content">
                                            <button className="close-button" onClick={handleCloseLightbox}>
                                                &times;
                                            </button>
                                            <button className="prev-button" onClick={handlePrevPhoto}>
                                                &lt;
                                            </button>
                                            <img
                                                src={`/assets/images/activities/${selectedSeries.photos[currentPhotoIndex]}`}
                                                alt={selectedSeries.title}
                                                className="lightbox-image"
                                            />
                                            <button className="next-button" onClick={handleNextPhoto}>
                                                &gt;
                                            </button>
                                            <div className="photo-counter">
                                                {currentPhotoIndex + 1} of {selectedSeries.photos.length}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;
};

export default Gallery;
