// src/pages/Ai.js
import React from 'react';

const About = () => {
    return <div>

        <div className="entry-image mb-5">
            <img src={`/assets/images/activities/banner_ai_project.jpg`}  />
        </div>

        <section className="page-title bg-transparent">
            <div className="container">
                <div className="page-title-row">
                    <div className="page-title-content">
                        <h1>AI Project</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">
						<div className="col-md-4">
                            <img src={`/assets/images/activities/planet_protectors_01.jpg`} />
                            <br /><br />
						</div>

						<div className="col-md-8">
                            <p>
                                I am passionate about both technology and environmental science, I embarked on an AI project aimed at uncovering the intricate relationships between climate change and infectious diseases. My project, titled “ClimateHealth AI,” seeks to leverage machine learning to analyze how environmental shifts driven by human activities influence the prevalence and spread of diseases such as malaria, dengue, and Zika. By understanding these connections, I hope to contribute to the growing body of knowledge that underscores the critical need for sustainable practices to protect public health.
							</p>
                            <p>
                                The first step of my project involved collecting a diverse range of data from reputable international organizations like the World Health Organization (WHO), the World Bank, and the Centers for Disease Control and Prevention (CDC). I gathered comprehensive datasets that included population statistics, economic indicators, incidence rates of infectious diseases, temperature records, and deforestation rates from various regions around the world. This extensive data collection was essential to ensure that my analysis would be robust and reflective of global trends. Additionally, I incorporated climate data from sources such as NASA and the National Oceanic and Atmospheric Administration (NOAA) to provide a comprehensive view of the environmental factors at play.
	                        </p>
                            <p>
                                Once the data was collected, the next critical phase was data sanitization. Raw data from multiple sources often contains inconsistencies, missing values, and errors that can skew analysis results. I meticulously cleaned the datasets by removing duplicates, filling in missing values through interpolation, and standardizing formats to ensure compatibility across different datasets. This process also involved normalizing the data to make it suitable for machine learning algorithms, which is crucial for accurate model training. By ensuring the integrity and quality of the data, I was able to create a solid foundation for meaningful analysis and reliable predictions.
                            </p>
							<p>
                                With a clean and well-organized dataset, I then applied various machine learning methods to train models that could identify and predict the effects of climate change on infectious diseases. I experimented with algorithms such as linear regression, decision trees, and neural networks to determine which approach provided the most accurate and insightful results. Through feature engineering and model tuning, I was able to highlight key environmental variables that significantly impact disease patterns. The models revealed compelling correlations between rising temperatures, deforestation, and the increased incidence of diseases like malaria and dengue, reinforcing the urgent need to address climate change as a public health priority.
							</p>
							<p>
                                This project holds profound significance for me because it bridges my interests in artificial intelligence and environmental advocacy. I am deeply concerned about how human-induced environmental changes are jeopardizing the health of populations worldwide. By using AI to shed light on these issues, I aim to raise awareness and encourage proactive measures to mitigate the adverse effects of climate change. “ClimateHealth AI” is not just an academic exercise; it is a testament to my commitment to leveraging technology for the greater good. I believe that by sharing my findings, I can inspire others to recognize the interconnectedness of our environment and public health, fostering a collective effort to create a healthier, more sustainable world.
							</p>
							{/* <a href={`/assets/media/pdf/Planet_Protectors.pdf`} >
								<img src={`/assets/images/activities/pdf_planet_protectors.jpg`} />
							</a> */}
                            <br />
                            <a href={`/assets/media/pdf/Planet_Protectors.pdf`} class="button button-border py-1 text-transform-none ls-0 fw-normal button-dark border-width-1 border-color h-bg-color">Read the Research Paper</a>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>;
};

export default About;
