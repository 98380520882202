// src/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {

    return <div>
        <section className="page-title bg-transparent">
            <div className="container">
                <div className="page-title-row">
                    <div className="page-title-content">
                        <h1>Privacy Policy</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">
                        <div className="col-12">
                            <p>
                                Last Updated: 8/26/2024
                            </p>
                            <p>
                                At jazlynn_chuo, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
                            </p>
                            <ol>
                                <li>
                                    <b>Information We Collect</b>
                                    <br />
                                    We may collect personal information that you voluntarily provide to us when you interact with our website, such as:
                                    <br />
                                    Personal Identification Information: Name, email address, phone number, and other similar contact data.
                                    <br />
                                    Technical Data: IP address, browser type, operating system, and other technical details about your device and internet connection.
                                    <br />
                                    Usage Data: Information about how you use our website, including the pages you visit, the time spent on each page, and other similar usage details.
                                    <br /><br />
                                </li>
                                <li>
                                    <b>How We Use Your Information</b>
                                    <br />
                                    We may use the information we collect from you in the following ways:
                                    <br />
                                    To provide and maintain our website.<br />
                                    To improve and personalize your experience on our website.<br />
                                    To respond to your inquiries, comments, or requests.<br />
                                    To send you updates, newsletters, or marketing communications (you may opt out at any time).<br />
                                    To monitor and analyze trends, usage, and activities in connection with our website.<br />
                                    To protect our website and our users from fraud, security threats, and other illegal activities.<br /><br />
                                </li>
                                <li>
                                    <b>Cookies and Tracking Technologies</b>
                                    <br />
                                    We use cookies and similar tracking technologies to collect and store information about your visit to our website. Cookies are small data files that are placed on your device when you visit a website. You can choose to disable cookies through your browser settings, but this may affect your ability to use certain features of our website.<br /><br />
                                </li>
                                <li>
                                    <b>Sharing Your Information</b><br />

                                    We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following situations:
                                    <br />
                                    Service Providers: We may share your information with third-party service providers who assist us in operating our website, conducting our business, or serving our users.<br />
                                    Legal Requirements: We may disclose your information if required by law or in response to valid legal requests from public authorities.<br />
                                    Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.<br /><br />
                                </li>
                                <li>
                                    <b>Security of Your Information</b><br />

                                    We take reasonable measures to protect the security of your personal information. However, no method of transmission over the internet or method of electronic storage is completely secure, and we cannot guarantee the absolute security of your information.<br /><br />
                                </li>
                                <li>
                                    <b>Your Rights and Choices</b><br />

                                    You have the right to:<br />

                                    Access, update, or delete your personal information.<br />
                                    Object to the processing of your personal information.<br />
                                    Request the restriction of processing your personal information.<br />
                                    Withdraw your consent at any time, where consent is the legal basis for processing your information.<br />
                                    To exercise any of these rights, please contact us using the information provided below.<br /><br />
                                </li>
                                <li>
                                    <b>Children's Privacy</b><br />

                                    Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently received personal information from a child under 13, we will delete such information from our records.<br /><br />
                                </li>
                                <li>
                                    <b>Changes to This Privacy Policy</b><br />

                                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page, and the date of the last update will be reflected at the top of this policy.<br /><br />
                                </li>
                                <li>
                                    <b>Contact Us</b><br />

                                    If you have any questions or concerns about this Privacy Policy or our practices, please contact us <a href="/contact">here</a><br />

                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

};

export default PrivacyPolicy;