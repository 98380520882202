// src/pages/Project.js
import React from 'react';

const Project = () => {
    return <div>
        <section className="page-title bg-transparent">
			<div className="container">
				<div className="page-title-row">
					<div className="page-title-content">
						<h1>Projects</h1>
					</div>
				</div>
			</div>
		</section>

		<section id="content">
			<div className="content-wrap">
				<div className="container">

					<div id="posts" className="row gutter-40">

						<div className="entry col-12">
							<div className="grid-inner row g-0">
								<div className="col-md-4">
									<div className="entry-image">
										<a href="/ai" data-lightbox="image">
											<img src="/assets/images/activities/banner_ai_project.jpg" alt="AI Project" />
										</a>
									</div>
								</div>
								<div className="col-md-8 ps-md-4">
									<div className="entry-title title-sm">
										<h2><a href="/ai">AI Project</a></h2>
									</div>
									{/* <div className="entry-meta">
										<ul>
											<li><i className="uil uil-schedule"></i> 10th Feb 2021</li>
											<li><a href="#"><i className="uil uil-user"></i> admin</a></li>
											<li><i className="uil uil-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
											<li><a href="blog-single.html#comments"><i className="uil uil-comments-alt"></i> 13</a></li>
											<li><a href="#"><i className="uil uil-camera"></i></a></li>
										</ul>
									</div> */}
									<div className="entry-content">
										<p>My AI project, titled “ClimateHealth AI,” seeks to leverage machine learning to analyze how environmental shifts driven by human activities influence the prevalence and spread of diseases such as malaria, dengue, and Zika. By understanding these connections, I hope to contribute to the growing body of knowledge that underscores the critical need for sustainable practices to protect public health.</p>
										<a href="/ai" className="more-link">Read More</a>
									</div>
								</div>
							</div>
						</div>

						<div className="entry col-12">
							<div className="grid-inner row g-0">
								<div className="col-md-4">
									<div className="entry-image">
										<a href="/planet_protector" data-lightbox="image">
											<img src="/assets/images/activities/banner_planet_protectors.jpg" alt="Planet Protectors" />
										</a>
									</div>
								</div>
								<div className="col-md-8 ps-md-4">
									<div className="entry-title title-sm">
										<h2><a href="/planet_protector">Planet Protectors</a></h2>
									</div>
									<div className="entry-content">
										<p>Planet Protectors is a project I created with a deep passion for environmental conservation and a commitment to making a difference. The idea for Planet Protectors was born out of my desire to empower the next generation with the knowledge and tools they need to combat climate change. I believe that even young children can play a vital role in protecting our planet, and through this project, I hope to inspire them to take action. My goal is to spread awareness about the critical issues of overconsumption, pollution, and environmental preservation in a way that is both positive and empowering.</p>
										<a href="/planet_protector" className="more-link">Read More</a>
									</div>
								</div>
							</div>
						</div>

						<div className="entry col-12">
							<div className="grid-inner row g-0">
								<div className="col-md-4">
									<div className="entry-image">
										<a href="/the_music_box_quartet" data-lightbox="image">
											<img src="/assets/images/the_music_box_wide_01.jpg" alt="The Music Box Quartet" />
										</a>
									</div>
								</div>
								<div className="col-md-8 ps-md-4">
									<div className="entry-title title-sm">
										<h2><a href="/the_music_box_quartet">The Music Box Quartet</a></h2>
									</div>
									<div className="entry-content">
										<p>My AI project, titled “ClimateHealth AI,” seeks to leverage machine learning to analyze how environmental shifts driven by human activities influence the prevalence and spread of diseases such as malaria, dengue, and Zika. By understanding these connections, I hope to contribute to the growing body of knowledge that underscores the critical need for sustainable practices to protect public health.</p>
										<a href="/the_music_box_quartet" className="more-link">Read More</a>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</section>
    </div>;
};

export default Project;
