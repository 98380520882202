// src/pages/Resume.js
import React from 'react';

const Resume = () => {
    return <div>
        <section className="page-title bg-transparent">
            <div className="container">
                <div className="page-title-row">
                    <div className="page-title-content">
                        <h1>Résumé</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">
                        <div className="col-2">
                            
                        </div>

                        <div className="col-8">
                            <div className="resume-line">
                                <h2>Education</h2>
                            </div>

                                <div className="resume-line bold">
                                    <span>Carlmont High School - Class of 2025</span>
                                    <span className="flush-right">Belmont, CA</span>
                                </div>

                            <div class="divider"></div>

                            <div className="resume-line">
                                <h2>Experience - On Campus</h2>
                            </div>

                                <div className="resume-line bold new-line">
                                    <span>Carlmont High School, Symphony Orchestra</span>
                                    <span className="flush-right">2021 to Present</span>
                                </div>
                                <div className="resume-line">
                                    <span>Vice Principal Cellist </span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>South East Asian Club</span>
                                    <span className="flush-right">2023 to Present</span>
                                </div>
                                <div className="resume-line">
                                    <span>Co-founder, Co-president</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Teacher Assistant for AP Biology</span>
                                    <span className="flush-right">2023 to Present</span>
                                </div>

                            <div class="divider"></div>

                            <div className="resume-line">
                                <h2>Experience - Off Campus</h2>
                            </div>

                                <div className="resume-line bold new-line">
                                    <span>Youth Climate Ambassadors</span>
                                    <span className="flush-right">2023 to Present</span>
                                </div>
                                <div className="resume-line">
                                    <span>Youth Leader, activist</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Peninsula Youth Orchestra, San Carlos</span>
                                    <span className="flush-right">2017 to Present</span>
                                </div>
                                <div className="resume-line">
                                    <span>Co-Principal Cellist</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Facilitator, Veterans Affairs Hospital</span>
                                    <span className="flush-right">February 2023 to Present</span>
                                </div>
                                <div className="resume-line">
                                    <span>Transported patients, worked closely with logistics staff, and worked with nurses in outpatient surgery and endoscopy.</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Quartet Performances, Senior Homes, VA Hospital, UCSF Benioff Children's Hospital, Redwood City Library</span>
                                    <span className="flush-right">December 2022 to Present</span>
                                </div>
                                <div className="resume-line">
                                    <span>Coordinating events, social networking, and creating music videos.</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Climate Change Education Lessons</span>
                                    <span className="flush-right">Summer 2024</span>
                                </div>
                                <div className="resume-line">
                                    <span>Founder, Taught 100+ preK-4 students, empowering them about climate change; Design experiments, multimedia, interactive activities; Consult NJ Climate Ed Dept</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>TA, Peninsula Youth Orchestra Summer Camp</span>
                                    <span className="flush-right">2023</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Volunteer, Animal Assisted Happiness</span>
                                    <span className="flush-right">2021 to 2023</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Volunteer, Second Harvest Food Bank	</span>
                                    <span className="flush-right">2021 to 2022</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Camp Counselor, Hiller Aviation Museum Summer Camp</span>
                                    <span className="flush-right">2021 and 2022</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Teacher, Music Mentors</span>
                                    <span className="flush-right">Oct-Dec 2022 & Jan 2024 to Present</span>
                                </div>
                                <div className="resume-line">
                                    <span>Teaching cello students.</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Palo Alto Chamber Orchestra, Palo Alto</span>
                                    <span className="flush-right">2018</span>
                                </div>
                                <div className="resume-line">
                                    <span>Cellist</span>
                                </div>

                            <div class="divider"></div>

                            <div className="resume-line">
                                <h2>Awards</h2>
                            </div>

                                <div className="resume-line bold new-line">
                                    <span>Certificate of Merit for Outstanding Achievement in Precalculus Honors</span>
                                    <span className="flush-right">2024</span>
                                </div>
                                <div className="resume-line">
                                    <span>Carlmont High School - Grade 11</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Gold International Classical Music Competition 2nd place</span>
                                    <span className="flush-right">2024</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>String Competition Online 2nd place</span>
                                    <span className="flush-right">2024</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Peninsula Youth Orchestra Concerto Competition Winner</span>
                                    <span className="flush-right">2023</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Certificate of Merit for Outstanding Achievement in Algebra II/Trigonometry</span>
                                    <span className="flush-right">2023</span>
                                </div>
                                <div className="resume-line">
                                    <span>Carlmont High School - Grade 10</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>President's Volunteer Service Award</span>
                                    <span className="flush-right">2022 and 2023</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Certificate of Merit for Outstanding Achievement in Geometry Enriched</span>
                                    <span className="flush-right">2022</span>
                                </div>
                                <div className="resume-line">
                                    <span>Carlmont High School - Grade 9</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Junior Bach Festival Outstanding Performance 69th Annual Celebration</span>
                                    <span className="flush-right">2022 and 2024</span>
                                </div>

                                <div className="resume-line bold new-line">
                                    <span>Carlmont High School Instrumental Music Program Outstanding Attitude</span>
                                    <span className="flush-right">2022</span>
                                </div>
                                <div className="resume-line">
                                    <span>Carlmont High School - Grade 9</span>
                                </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;
};

export default Resume;
