// src/pages/Blog.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Blog = () => {

    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchBlogs(currentPage);
    }, [currentPage]);

    const fetchBlogs = (page) => {
        axios.get(`https://api.jazlynnchuo.com/api/blogs?page=${page}&limit=6`)
            .then(response => {
                setBlogs(response.data.blogs);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error('There was an error fetching the blog articles!', error);
            });
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return <div>
        <section className="page-title bg-transparent">
            <div className="container">
                <div className="page-title-row">
                    <div className="page-title-content">
                        <h1>Blog</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">

                    <ul className="pagination justify-content-center">
                        <li className="page-item" onClick={() => handlePageChange(1)} disabled={currentPage === 1} title="First Page"><a className="page-link" href="#" aria-label="Next"><span aria-hidden="true">&laquo;</span></a></li>
                        <li className="page-item" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} title="Previous Page"><a className="page-link" href="#" aria-label="Previous"> <span aria-hidden="true">&lt;</span></a></li>
                        {[...Array(totalPages)].map((_, i) => (
                            <li 
                                key={i + 1}
                                onClick={() => handlePageChange(i + 1)}
                                className={currentPage === i + 1 ? 'page-item active' : 'page-item'}
                                title={`Page ${i + 1}`}
                            >
                                <a className="page-link" href="#">{i + 1}</a>
                            </li>
                        ))}
                        <li className="page-item" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} title="Next Page"><a className="page-link" href="#" aria-label="Next"><span aria-hidden="true">&gt;</span></a></li>
                        <li className="page-item" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} title="Last Page"><a className="page-link" href="#" aria-label="Next"><span aria-hidden="true">&raquo;</span></a></li>
					</ul>

                    <div id="posts" className="post-grid row grid-container gutter-40">
                        {blogs.map(blog => (
                            <div className="entry col-md-4 col-sm-6 col-12" key={blog.id}>
                                <div className="grid-inner">
                                    <div className="entry-image">
                                        <a href={`/blog/${blog.id}`} data-lightbox="image" title={blog.title}><img src={`/assets/images/portrait_jazlynn_chuo.jpg`} alt={blog.title} /></a>
                                    </div>
                                    <div className="entry-title">
                                        <h2><a href={`/blog/${blog.id}`} title={blog.title}>{blog.title}</a></h2>
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            <li><i className="uil uil-schedule"></i> {new Date(blog.created_at).toLocaleDateString()}</li>
                                        </ul>
                                    </div>
                                    <div className="entry-content">
                                        <p>{blog.content.substring(0, 100)}...</p>
                                        <a href={`/blog/${blog.id}`} className="more-link" title={blog.title}>Read More</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <ul className="pagination justify-content-center">
                        <li className="page-item" onClick={() => handlePageChange(1)} disabled={currentPage === 1} title="First Page"><a className="page-link" href="#" aria-label="Next"><span aria-hidden="true">&laquo;</span></a></li>
                        <li className="page-item" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} title="Previous Page"><a className="page-link" href="#" aria-label="Previous"> <span aria-hidden="true">&lt;</span></a></li>
                        {[...Array(totalPages)].map((_, i) => (
                            <li 
                                key={i + 1}
                                onClick={() => handlePageChange(i + 1)}
                                className={currentPage === i + 1 ? 'page-item active' : 'page-item'}
                                title={`Page ${i + 1}`}
                            >
                                <a className="page-link" href="#">{i + 1}</a>
                            </li>
                        ))}
                        <li className="page-item" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} title="Next Page"><a className="page-link" href="#" aria-label="Next"><span aria-hidden="true">&gt;</span></a></li>
                        <li className="page-item" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} title="Last Page"><a className="page-link" href="#" aria-label="Next"><span aria-hidden="true">&raquo;</span></a></li>
					</ul>

                </div>
            </div>
        </section>
    </div>;

};

export default Blog;
