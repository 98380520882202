// src/pages/TermsOfUse.js
import React from 'react';

const TermsOfUse = () => {

    return <div>
        <section className="page-title bg-transparent">
            <div className="container">
                <div className="page-title-row">
                    <div className="page-title-content">
                        <h1>Terms Of Use</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="content">
			<div className="content-wrap">
				<div className="container">
                    <div className="row col-mb-50">
                        <div className="col-12">
                            <p>Last Updated: 8/25/2024</p>

                            <p>Welcome to jazlynn_chuo! By accessing or using our website, you agree to comply with and be bound by the following terms and conditions of use (the "Terms of Use"). Please read these Terms of Use carefully before using our website.</p>

                            <ol>
                                <li>
                                    <b>Acceptance of Terms</b><br />
                                    By using the jazlynn_chuo website, you agree to these Terms of Use. If you do not agree with any part of these terms, you must not use this website.
                                </li>
                                <br />
                                <li>
                                    <b>Changes to Terms</b><br />
                                    We reserve the right to modify, update, or change these Terms of Use at any time without prior notice. Your continued use of the website after any changes constitutes your acceptance of the new Terms of Use.
                                </li>
                                <br />
                                <li>
                                    <b>Use of the Website</b><br />
                                    You agree to use the website for lawful purposes only and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of the website by, any third party. You must not use the website to transmit any harmful or unlawful content.
                                </li>
                                <br />
                                <li>
                                    <b>Intellectual Property</b><br />
                                    All content on this website, including text, graphics, logos, images, and software, is the property of jazlynn_chuo or its content suppliers and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from this content without our express written permission.
                                </li>
                                <br />
                                <li>
                                    <b>User Content</b><br />
                                    If you submit any content to the website, including comments, reviews, or other material, you grant jazlynn_chuo a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, and distribute such content in any medium.
                                </li>
                                <br />
                                <li>
                                    <b>Privacy</b><br />
                                    Your use of the website is also governed by our Privacy Policy. Please review the <a href="/privacy_policy">Privacy Policy</a> to understand our practices regarding the collection, use, and disclosure of your personal information.
                                </li>
                                <br />
                                <li>
                                    <b>Disclaimer of Warranties</b><br />
                                    The website is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that the website will be error-free, uninterrupted, or free of viruses or other harmful components.
                                </li>
                                <br />
                                <li>
                                    <b>Limitation of Liability</b><br />
                                    In no event shall jazlynn_chuo, its affiliates, or their respective officers, directors, employees, or agents be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the website, even if advised of the possibility of such damages.
                                </li>
                                <br />
                                <li>
                                    <b>Indemnification</b><br />
                                    You agree to indemnify, defend, and hold harmless jazlynn_chuo, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising out of or in connection with your use of the website or any violation of these Terms of Use.
                                </li>
                                <br />
                                <li>
                                    <b>Governing Law</b><br />
                                    These Terms of Use shall be governed by and construed in accordance with the laws of California, United States, without regard to its conflict of law principles. Any legal action or proceeding arising under these Terms of Use shall be brought exclusively in the courts of California, United States.
                                </li>
                                <br />
                                <li>
                                    <b>Contact Information</b><br />
                                    If you have any questions or concerns about these Terms of Use, please contact us <a href="/contact">here</a>.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

};

export default TermsOfUse;