// src/reducers/contactReducer.js
import { createReducer } from '@reduxjs/toolkit';
import { submitContact } from '../actions/contactActions';

const initialState = {
  submissions: [],
};

const contactReducer = createReducer(initialState, (builder) => {
  builder.addCase(submitContact, (state, action) => {
    state.submissions.push(action.payload);
  });
});

export default contactReducer;
