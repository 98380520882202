import React, { useState, useEffect } from 'react';

const Contact = () => {

    const [csrfToken, setCsrfToken] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const [popup, setPopup] = useState({ visible: false, message: '', success: false });

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await fetch('https://api.jazlynnchuo.com/get-csrf-token', {
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                
                setCsrfToken(data.csrfToken);
            } catch (error) {
                console.error('Failed to fetch CSRF token:', error);
                alert('Failed to fetch CSRF token. Please try again later.');
            }
        };

        fetchCsrfToken();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('CSRF Token:', csrfToken);

        try {
            const response = await fetch('https://api.jazlynnchuo.com/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                credentials: 'include',
                body: JSON.stringify(formData),
            });

            const result = await response.json();
            if (result.success) {
                setPopup({ 
                    visible: true, 
                    message: 'Thank you for contacting me. I will reply to you as soon as possible.', 
                    success: true 
                });
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: ''
                });
            } else {
                setPopup({ 
                    visible: true, 
                    message: 'Oops, there was an error submitting the form, please try again later, thank you.', 
                    success: false 
                });
            }
        } catch (error) {
            console.error('Error:', error);
            setPopup({ 
                visible: true, 
                message: 'Oops, there was an error submitting the form, please try again later, thank you.', 
                success: false 
            });
        }
    };

    const closePopup = () => {
        setPopup({ visible: false, message: '', success: false });
    };

    return ( 
        <div>
            <section className="page-title bg-transparent">
                <div className="container">
                    <div className="page-title-row">
                        <div className="page-title-content">
                            <h1>Contact Me</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section id="content">
                <div className="content-wrap">
                    <div className="container">
                        <div className="row col-mb-50">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="row align-items-stretch col-mb-50 mb-0">
                                    <div className="">
                                        <div className="form-widget">
                                            <div className="form-result"></div>
                                            <form className="mb-0" id="template-contactform" onSubmit={handleSubmit}>
                                                <div className="form-process">
                                                    <div className="css3-spinner">
                                                        <div className="css3-spinner-scaler"></div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4 form-group">
                                                        <label htmlFor="template-contactform-name">Name <small>*</small></label>
                                                        <input 
                                                            type="text" 
                                                            id="template-contactform-name" 
                                                            name="name" 
                                                            value={formData.name} 
                                                            onChange={handleChange} 
                                                            className="form-control required" 
                                                            required 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 form-group">
                                                        <label htmlFor="template-contactform-email">Email <small>*</small></label>
                                                        <input 
                                                            type="email" 
                                                            id="template-contactform-email" 
                                                            name="email" 
                                                            value={formData.email} 
                                                            onChange={handleChange} 
                                                            className="required email form-control" 
                                                            required 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 form-group">
                                                        <label htmlFor="template-contactform-phone">Phone</label>
                                                        <input 
                                                            type="text" 
                                                            id="template-contactform-phone" 
                                                            name="phone" 
                                                            value={formData.phone} 
                                                            onChange={handleChange} 
                                                            className="form-control" 
                                                        />
                                                    </div>

                                                    <div className="w-100"></div>

                                                    <div className="col-md-8 form-group">
                                                        <label htmlFor="template-contactform-subject">Subject <small>*</small></label>
                                                        <input 
                                                            type="text" 
                                                            id="template-contactform-subject" 
                                                            name="subject" 
                                                            value={formData.subject} 
                                                            onChange={handleChange} 
                                                            className="required form-control" 
                                                            required 
                                                        />
                                                    </div>

                                                    <div className="w-100"></div>

                                                    <div className="col-12 form-group">
                                                        <label htmlFor="template-contactform-message">Message <small>*</small></label>
                                                        <textarea 
                                                            className="required form-control" 
                                                            id="template-contactform-message" 
                                                            name="message" 
                                                            value={formData.message} 
                                                            onChange={handleChange} 
                                                            rows="6" 
                                                            cols="30" 
                                                            required 
                                                        ></textarea>
                                                    </div>

                                                    <div className="col-12 form-group">
                                                        <button 
                                                            name="submit" 
                                                            type="submit" 
                                                            id="submit-button" 
                                                            tabIndex="5" 
                                                            value="Submit" 
                                                            className="button button-3d m-0"
                                                        >
                                                            Submit Comment
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 min-vh-20">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </div>
            </section>

            {popup.visible && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p>{popup.message}</p>
                        <button className="button button-3d m-0" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Contact;
